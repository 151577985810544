import React, {useEffect, useMemo, useState} from "react";
import "./Blog.css";
import PropTypes from "prop-types";
import AdditionalBlogCard from "../../pages/blog/components/AdditionalBlogCard";

function RelatedBlogComponent({blogs}) {
    const [relatedBlogs, setRelatedBlogs] = useState([]);
    const showAllBlogs = false;

    const memoizedRelatedBlogContent = useMemo(() => {
        return createResponse(blogs);
    }, [blogs]);

    useEffect(() => {
        if (memoizedRelatedBlogContent) {
            setRelatedBlogs(memoizedRelatedBlogContent.relatedBlogs);
        }
    }, [memoizedRelatedBlogContent]);

    const blogsToShow = showAllBlogs ? relatedBlogs : relatedBlogs.slice(0, 3);

    return (
        <section className="row section-spacing">
            {
                blogsToShow.map((blog) => (
                    <AdditionalBlogCard key={blog.slug} blog={blog}/>
                ))
            }
        </section>
    );

    function createResponse(blogs) {
        if (blogs) {
            const relatedBlogs = blogs.map((blog) => {
                const blogsContent = blog.attributes ? blog.attributes : [];
                if (blogsContent) {
                    const image = blogsContent.image ? blogsContent.image.data.attributes : [];
                    const button = blogsContent.button ? blogsContent.button : [];
                    return {
                        id: blog.id,
                        image: image.url,
                        alt: image.alternativeText,
                        heading: blogsContent.heading,
                        summary: blogsContent.summary,
                        button: button.title,
                        open_view_window: button.open_new_window,
                        categories: blogsContent.categories,
                    }
                }

                return null;
            });

            return {relatedBlogs}
        }
    }
}

RelatedBlogComponent.propTypes = {
  blogs: PropTypes.array
};

export default RelatedBlogComponent;
