import {getImageUrl} from "../../../services/util";
import React from "react";
import PropTypes from "prop-types";

function AdditionalBlogCard({blog}) {
    return (
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-4 d-inline-grid">
            <div className="card">
                <div className="card-body d-inline-grid blog-card-body">
                    {
                        blog.image ?
                            <div className="blog-image-container text-center">
                                <img className="img-fluid secondary-blog-image" alt={blog.alt}
                                     src={getImageUrl(blog.image)}/>
                            </div> : null
                    }

                    <div className="blog-category-container">
                        <div className="badge blog-badge">
                            {blog.categories}
                        </div>
                    </div>

                    <div className="blog-heading-container">
                        <a href={"/blog/" + blog.slug}>
                            <h5 className="mb-0">{blog.heading}</h5>
                        </a>
                    </div>

                    <div className="Secondary-Blog-Summary">{blog.summary}</div>

                    {
                        blog.button ? <a className="btn-primary-link" href={"/blog/" + blog.slug}
                                         target={blog.open_view_window ? "_blank" : ""}
                                         rel="noopener noreferrer">
                            {blog.button}
                        </a> : null
                    }
                </div>
            </div>
        </div>
    )
}

AdditionalBlogCard.propTypes = {
    blog: PropTypes.object.isRequired
};

export default AdditionalBlogCard;