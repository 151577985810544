import React, {useState} from "react";
import "./Blog.css";
import PropTypes from "prop-types";
import AdditionalBlogCard from "../../pages/blog/components/AdditionalBlogCard";

function SecondaryBlogComponent({blogs}) {
    const [showAllBlogs, setShowAllBlogs] = useState(false);
    const blogsToShow = showAllBlogs ? blogs : blogs.slice(0, 9);

    return (
        <div className="row section-spacing">
            {
                blogsToShow.map((blog) => (
                    <AdditionalBlogCard key={blog.slug} blog={blog}/>
                ))
            }

            {blogs.length > 9 && !showAllBlogs && (
                <div className="col-12 text-center">
                    <button onClick={() => setShowAllBlogs(true)}
                            className="load-more-btn">
                        Load More
                    </button>
                </div>
            )}
        </div>
    );
}

SecondaryBlogComponent.propTypes = {
    blogs: PropTypes.array.isRequired
};

export default SecondaryBlogComponent;
