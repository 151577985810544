import PrimaryBlogComponent from "../../components/blog/primaryBlog.js";
import "../../components/blog/Blog.css";
import "../home/Home.css";
import SecondaryBlogComponent from "../../components/blog/secondaryBlog.js";
import useFetch from "../../hooks/useFetch";
import React, {useEffect, useMemo, useState} from "react";
import LoadingComponent from "../../components/LoadingComponent";
import ErrorHandling from "../../components/ErrorHandling";
import SEOMetaTags from "../../components/SEOMetaTags";

function Blog() {
    const apiId = "/blog-contents";

    const url = process.env.REACT_APP_API_PATH + apiId + "?populate[0]=image&populate[1]=button&filters[$and][0][is_active][$eq]=true";
    const {response, loading, error} = useFetch(url);

    const [primaryBlog, setPrimaryBlog] = useState(null);
    const [secondaryBlog, setSecondaryBlog] = useState([]);

    const memoizedBlogContent = useMemo(() => {
        return createResponse(response);
    }, [response]);

    useEffect(() => {
        if (memoizedBlogContent) {
            setPrimaryBlog(memoizedBlogContent.primaryBlog);
            setSecondaryBlog(memoizedBlogContent.secondaryBlog);
        }
    }, [memoizedBlogContent]);

    if (loading) return <LoadingComponent/>;

    return (
        error ? (<ErrorHandling error={error.message}/>) :
            (
                <section>
                    <SEOMetaTags apiId={"/blog"} filters=""/>

                    <div className="container">
                        <div className="container-fluid section-spacing">
                            <div className="blog-main-container text-center">
                                <div className="banner-heading-container">
                                    <h1>The MiS Blog</h1>
                                </div>

                                <div className="blog-description">
                                    Keeping you updated and informed on the latest happening in IT
                                    protection.
                                </div>
                            </div>

                            <div className="blog-content-main-container">
                                {
                                    primaryBlog && Object.keys(primaryBlog).length !== 0 ?
                                        <div className="primary-blog-container">
                                            <PrimaryBlogComponent blog={primaryBlog}/>
                                        </div>
                                        : null
                                }

                                {
                                    secondaryBlog.length > 0 ?
                                        <div className="secondary-blog-container">
                                            <SecondaryBlogComponent blogs={secondaryBlog}/>
                                        </div> :

                                        <div className="empty-message text-center">
                                            No blogs have been created yet.
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            )
    );

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data : [];

            if (data) {
                let primaryBlog = {};
                const secondaryBlog = [];

                data.map((blog) => {
                    const blogsContent = blog.attributes ? blog.attributes : [];
                    if (blogsContent) {
                        const image = blogsContent.image ? blogsContent.image.data.attributes : [];
                        const button = blogsContent.button ? blogsContent.button : [];
                        if (blogsContent.is_primary) {
                            primaryBlog = {
                                id: blog.id,
                                slug: blogsContent.slug,
                                image: image.url,
                                alt: image.alternativeText,
                                heading: blogsContent.heading,
                                summary: blogsContent.summary,
                                button: button.title,
                                open_view_window: button.open_new_window,
                                categories: blogsContent.categories,
                            };
                        } else {
                            secondaryBlog.push({
                                id: blog.id,
                                slug: blogsContent.slug,
                                image: image.url,
                                alt: image.alternativeText,
                                heading: blogsContent.heading,
                                summary: blogsContent.summary,
                                button: button.title,
                                open_view_window: button.open_new_window,
                                categories: blogsContent.categories,
                            });
                        }
                    }

                    return null;
                });

                return {primaryBlog, secondaryBlog}
            }
        }
    }
}

export default Blog;
