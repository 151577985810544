import {Route, Routes} from "react-router-dom";
import Home from "../home/Home";
import Solutions from "../solutions/Solutions";
import Shop from "../shop/Shop";
import Blog from "../blog/Blog";
import BlogDetailComponent from "../blog/BlogDetail";
import Layout from "../../components/Layout";
import Dashboard from "../dashboard/dashboard";
import PrivateRoute from "../PrivateRoute";
import ShoppingCart from "../checkout/ShoppingCart";
import Payment from "../checkout/Payment";
import Confirmation from "../checkout/Confirmation";
import Company from "../company/Company";
import PrivacyPolicy from "../privacy-policy/PrivacyPolicy"
import ContactUs from "../contactus/ContactUsMain"
import RelatedServices from "../related-services/RelatedServices";
import TermsAndConditions from "../terms-and-conditions/TermsAndConditions";
import FAQ from "../faq/FAQ";
import LoggedInLayout from "../../components/LoggedInLayout";
import SoftwareDownload from "../client-portal/SoftwareDownload";
import StripeCustomerPortal from "../client-portal/StripeCustomerPortal";
import AccountInformation from "../client-portal/AccountInformation";
import Orders from "../client-portal/Orders";
import ActivateInsurance from "../client-portal/ActivateInsurance";

export const AppRouter = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <Layout>
                        <Home/>
                    </Layout>
                }
            />
            <Route
                path="/company"
                element={
                    <Layout>
                        <Company/>
                    </Layout>
                }
            />
            <Route
                path="/solutions/:slug"
                element={
                    <Layout>
                        <Solutions apiId={"/product-details"}/>
                    </Layout>
                }
            />

            <Route
                path="/pricing-and-plans"
                element={
                    <Layout>
                        <Shop slug={"/pricing-and-plans"} heading={"Check Out Our Products"} cat={""}/>
                    </Layout>
                }
            />

        <Route
                path={"/pricing-and-plans/:category.slug"}
                element={
                    <Layout>
                        <Shop slug={"/pricing-and-plans"} heading={"Check Out Our Products"} cat={"category.slug"}/>
                    </Layout>
                }
            />
            

            <Route
                path="/blog"
                element={
                    <Layout>
                        <Blog/>
                    </Layout>
                }
            />

            <Route
                path="/blog/:slug"
                element={
                    <Layout>
                        <BlogDetailComponent apiId={"/blog"}/>
                    </Layout>
                }
            />

            <Route
                path="/dashboard"
                element={
                    <PrivateRoute>
                        <LoggedInLayout>
                            <Dashboard/>
                        </LoggedInLayout>
                    </PrivateRoute>
                }
            />

            <Route
                path="/software-download"
                element={
                    <PrivateRoute>
                        <LoggedInLayout>
                            <SoftwareDownload/>
                        </LoggedInLayout>
                    </PrivateRoute>
                }
            />

            <Route
                path="/insurance-activation"
                element={
                    <PrivateRoute>
                        <LoggedInLayout>
                            <ActivateInsurance/>
                        </LoggedInLayout>
                    </PrivateRoute>
                }
            />

            <Route
                path="/account-information"
                element={
                    <PrivateRoute>
                        <LoggedInLayout>
                            <AccountInformation/>
                        </LoggedInLayout>
                    </PrivateRoute>
                }
            />

            <Route
                path="/orders"
                element={
                    <PrivateRoute>
                        <LoggedInLayout>
                            <Orders/>
                        </LoggedInLayout>
                    </PrivateRoute>
                }
            />

            <Route
                path="/payment-information"
                element={
                    <PrivateRoute>
                        <LoggedInLayout>
                            <StripeCustomerPortal heading="Payment Information"/>
                        </LoggedInLayout>
                    </PrivateRoute>
                }
            />

            <Route
                path="/view-subscriptions"
                element={
                    <PrivateRoute>
                        <LoggedInLayout>
                            <StripeCustomerPortal heading="View Subscriptions"/>
                        </LoggedInLayout>
                    </PrivateRoute>
                }
            />

            <Route
                path="/buy-more"
                element={
                    <PrivateRoute>
                        <LoggedInLayout>
                            <Shop slug={"/buy-more"} heading={"Buy More"}/>
                        </LoggedInLayout>
                    </PrivateRoute>
                }
            />

            <Route
                path="/shopping-cart"
                element={
                    <Layout>
                        <ShoppingCart/>
                    </Layout>
                }
            />

            <Route
                path="/auth/shopping-cart"
                element={
                    <PrivateRoute>
                        <LoggedInLayout>
                            <ShoppingCart/>
                        </LoggedInLayout>
                    </PrivateRoute>
                }
            />

            <Route
                path="/payment"
                element={
                    <Layout>
                        <Payment/>
                    </Layout>
                }
            />

            <Route
                path="/confirmation"
                element={
                    <Layout>
                        <Confirmation/>
                    </Layout>
                }
            />

            <Route
                path="/privacy-policy"
                element={
                    <Layout>
                        <PrivacyPolicy/>
                    </Layout>
                }
            />

            <Route
                path="/terms-and-conditions"
                element={
                    <Layout>
                        <TermsAndConditions/>
                    </Layout>
                }
            />

            <Route
                path="/faq"
                element={
                    <Layout>
                        <FAQ/>
                    </Layout>
                }
            />

            <Route
                path="/contact-us"
                element={
                    <Layout>
                        <ContactUs/>
                    </Layout>
                }
            />

            <Route path="/related-services"
                   element={
                       <Layout>
                           <RelatedServices/>
                       </Layout>
                   }
            />
        </Routes>
    );
};
